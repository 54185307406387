const baseUrl = 'https://api.match-flix.de'; // development
export const partner = 'personaltotal'; // personaltotal matchflix senator lienert // change for test purposes
// logic which view to show
export const isLienert = partner === 'lienert'; // dont change!!!
export const isMatchflix = partner === 'matchflix'; // dont change!!!
export const isSenatorInterim = partner === 'interim'; // dont change!!!
export const isSenator = partner === 'senator';
export const isPersonalTotal = partner === 'personaltotal';

export const excludeMenu = {
  senator: ['/offers']
};

export function domain() {
  switch (partner) {
    case 'matchflix':
      return 'match-flix.de';
    case 'personaltotal':
      return 'personal-total.de';
    case 'senator':
      return 'senator-partners.de';
    case 'lienert':
      // return 'joerg-lienert.ch';
      return '';
    case 'interim':
      return 'interim.senator-partners.de';
    default:
      return '';
  }
}

export function parserMailAddress() {
  if (domain() === '') return '';
  const prefix = 'mailparser';
  const mailAddress = `${prefix}@${domain()}`;
  return mailAddress;
  // return '';
}

export const testUsers = [
  '5bc5e136233d354dac83f6d2', // Simon dev
  '5bac03bcb2abda2670253e34', // 	m.barhainski@senator-partners.de prod
  '5bd1ba8021dde851a36627ab', // Simon prod
  '5c33cdb7605d3f5115d12ebd' // Monika prod
];
// urls
export const amountOfResultsURL = `${baseUrl}/getAmountOfResults`;
export const getSuggestionsURL = `${baseUrl}/getSuggestionSearchTerms`;
export const getIntelligentSearchSuggestionsURL = `${baseUrl}/getIntelligentSearchSuggestions`;

export const getSearchResultsURL = `${baseUrl}/getSearchResults`;
export const getNASearchResultsURL = `${baseUrl}/getNASearchResults`;
export const getSearchResultsByMarkListIdURL = `${baseUrl}/getSearchResultsByMarkListId`;
export const getCandidateImage2URL = `${baseUrl}/getCandidateImages`;
export const loginURL = `${baseUrl}/login`;
export const logoutURL = `${baseUrl}/logout`;
export const verifyTokenURL = `${baseUrl}/user`;
export const saveSearchURL = `${baseUrl}/saveSearch`;
export const getSavedSearchesURL = `${baseUrl}/getSavedSearches`;
export const getDeleteSearchesURL = `${baseUrl}/deleteSavedSearch`;
export const sendOrderURL = `${baseUrl}/sendOrder`;
export const getCandidateDocumentByIdURL = `${baseUrl}/getCandidateDocumentById`;
export const inviteUserToRegistrationURL = `${baseUrl}/inviteUserToRegistration`;
export const getUsers = `${baseUrl}/users`;
export const registerURL = `${baseUrl}/register`;
export const get2FASecretURL = `${baseUrl}/get2FASecret`;
export const verifyTanURL = `${baseUrl}/verifyTan`;
export const addItemToMarkListURL = `${baseUrl}/addItemToMarkList`;
export const loadMarkListsURL = `${baseUrl}/loadMarkLists`;
export const loadCounterPartListsURL = `${baseUrl}/loadCounterPartLists`;
export const loadCounterPartsForMarkURL = `${baseUrl}/loadCounterPartsForMark`;
export const loadPopulatedMarkListsURL = `${baseUrl}/loadPopulatedMarkLists`;
export const deleteMarkListURL = `${baseUrl}/deleteMarkList`;
export const shareMarkListURL = `${baseUrl}/shareMarkList`;
export const removeItemFromMarkListURL = `${baseUrl}/removeItemFromMarkList`;
export const deleteUserURL = `${baseUrl}/deleteUser`;
export const resetUserURL = `${baseUrl}/resetUser`;
export const getCompanyURL = `${baseUrl}/getCompany`;
export const createUserFromCounterPartURL = `${baseUrl}/createUserFromCounterPart`;
export const updateCounterPartUserStatusURL = `${baseUrl}/updateCounterPartUserStatus`;
export const exportCompanyCSVURL = `${baseUrl}/exportCompanyCSV`;
export const exportChristmasCSVURL = `${baseUrl}/exportChristmasCSV`;
export const getCompanyListByIdsURL = `${baseUrl}/getCompanyListByIds`;
export const getCompanyListByCompanyIdsURL = `${baseUrl}/getCompanyListByCompanyIds`;
export const getCompanyListBySearchTermURL = `${baseUrl}/getCompanyListBySearchTerm`;
export const getCompanyListByDetailSearchTermsURL = `${baseUrl}/getCompanyListByDetailSearchTerms`;
export const getCounterPartByDetailSearchTermsExportURL = `${baseUrl}/getCounterPartByDetailSearchTermsExport`;
export const createCompanyContactURL = `${baseUrl}/createCompanyContact`;
export const createCompanyContactHistoryEntryURL = `${baseUrl}/createCompanyContactHistoryEntry`;
export const updateCompanyContactHistoryEntryURL = `${baseUrl}/updateCompanyContactHistoryEntry`;
export const updateCounterPartDetailsURL = `${baseUrl}/updateCounterPartDetails`;

export const getAllPartnersOfCompanyURL = `${baseUrl}/getAllPartnersOfCompany`;
export const getAllPartnersURL = `${baseUrl}/getAllPartners`;
export const getPartnersByBrandURL = `${baseUrl}/getPartnersByBrand`;
export const getPartnerByIdURL = `${baseUrl}/getPartnerById`;
export const updatePartnerURL = `${baseUrl}/updatePartner`;
export const deletePartnerURL = `${baseUrl}/deletePartner`;
export const createPartnerURL = `${baseUrl}/createPartner`;
export const reactivatePartnerURL = `${baseUrl}/reactivatePartner`;
export const updateCompanyStatusURL = `${baseUrl}/updateCompanyStatus`;
export const updateCompanyLogoURL = `${baseUrl}/updateCompanyLogo`;

export const createCompanyURL = `${baseUrl}/createCompany`;
export const updateCompanyDetailsURL = `${baseUrl}/updateCompany`;

export const candidatePlaceholderPictureURL = '/avatar.png';

export const getUserFromCounterPartURL = `${baseUrl}/getUserFromCounterPart`;
export const getAccessibleProjectListForCounterPartURL = `${baseUrl}/getAccessibleProjectsByCounterPartId`;
export const updateAccessibleProjectsURL = `${baseUrl}/updateAccessibleProjects`;
export const getUserActivityURL = `${baseUrl}/getUserActivityByCounterPartId`;

export const getCompanyChangeHistoryURL = `${baseUrl}/getCompanyChangeHistory`;
export const getProjectChangeHistoryURL = `${baseUrl}/getProjectChangeHistory`;
export const deleteCounterPartRemindersURL = `${baseUrl}/deleteCounterPartReminders`;
export const uploadXlsForCompanyImportURL = `${baseUrl}/uploadXlsForCompanyImport`;
export const uploadXlsForJobFeedImportURL = `${baseUrl}/uploadXlsForJobFeedImport`;
export const downloadExcelExampleFileURL = `${baseUrl}/downloadExcelExampleFile`;
export const deleteCompanyURL = `${baseUrl}/deleteCompany`;
export const getCompanyDocumentByIdURL = `${baseUrl}/getCompanyDocumentById`;
export const uploadCompanyDocumentsURL = `${baseUrl}/uploadCompanyDocuments`;
export const deleteCompanyDocumentURL = `${baseUrl}/deleteCompanyDocument`;
export const deleteCompanyContactURL = `${baseUrl}/deleteCompanyContact`;
export const toggleChristmasExportURL = `${baseUrl}/toggleChristmasExport`;
export const deleteCompanyContactHistoryEntryURL = `${baseUrl}/deleteCompanyContactHistoryEntry`;
export const deleteAllDocumentsOfCandidatesInListURL = `${baseUrl}/deleteAllDocumentsOfCandidatesInList`;
export const geoLocationLookUpURL = `${baseUrl}/geoLocationLookUp`;

// Notifications and Reminders
export const markCounterPartRemindersAsDoneURL = `${baseUrl}/markCounterPartRemindersAsDone`;
export const markNotificationsAsReadURL = `${baseUrl}/markNotificationsAsRead`;
export const getCounterPartReminderListURL = `${baseUrl}/getCounterPartReminderList`;
export const getCounterPartReminderListByCompanyURL = `${baseUrl}/getCounterPartReminderListByCompany`;
export const getAllNotificationsOfUserURL = `${baseUrl}/getAllNotificationsOfUser`;
export const createCounterPartReminderURL = `${baseUrl}/createCounterPartReminder`;
export const updateCounterPartReminderURL = `${baseUrl}/updateCounterPartReminder`;
// ProjectArchive
export const getProjectByIdURL = `${baseUrl}/getProjectById`;
export const createProjectURL = `${baseUrl}/createProject`;
export const getEmployeeListByPartnerURL = `${baseUrl}/getEmployeeListByPartner`;
export const updateProjectURL = `${baseUrl}/updateProject`;
export const updateProjectStatusStepstoneURL = `${baseUrl}/updateProjectStatusStepstone`;
export const getProjectListBySearchTermURL = `${baseUrl}/getProjectListBySearchTerm`;
export const getProjectListByDetailSearchTermsURL = `${baseUrl}/getProjectListByDetailSearchTerms`;
export const getProjectListCompanyIdURL = `${baseUrl}/getProjectListByCompanyId`;
export const createActionProtocolURL = `${baseUrl}/createActionProtocol`;
export const updatePotentialCandidateURL = `${baseUrl}/updatePotentialCandidate`;
export const updatePotentialCandidatesURL = `${baseUrl}/updatePotentialCandidates`;
export const getCandidateLienertDossierURL = `${baseUrl}/getCandidateLienertDossier`;
export const deleteActionProtocolURL = `${baseUrl}/deleteActionProtocol`;
export const getProjectListByLastUpdateURL = `${baseUrl}/getProjectListByLastUpdate`;
export const getProjectListByMarkListIdURL = `${baseUrl}/getProjectListByMarkListId`;
export const updateProjectSubSchemaURL = `${baseUrl}/updateProjectSubSchema`;
export const updateProjectPictureURL = `${baseUrl}/updateProjectPicture`;
export const uploadProjectDocumentsURL = `${baseUrl}/uploadProjectDocuments`;
export const downloadProjectDocumentURL = `${baseUrl}/downloadProjectDocument`;
export const deleteProjectDocumentURL = `${baseUrl}/deleteProjectDocument`;
export const removePotentialCandidateURL = `${baseUrl}/removePotentialCandidate`;
export const getProjectStatusReportURL = `${baseUrl}/getProjectStatusReport`;
export const deleteProjectURL = `${baseUrl}/deleteProject`;
export const getJobPostingPdfURL = `${baseUrl}/getJobPostingPdf`;
export const getJobPostingHTMLURL = `${baseUrl}/getJobPostingHTML`;
export const notifyClientJobPostingReleaseURL = `${baseUrl}/notifyClientJobPostingRelease`;
export const getProjectAdvertDocumentURL = `${baseUrl}/getProjectAdvertDocument`;
export const getProjectDocumentByIdURL = `${baseUrl}/getProjectDocumentById`;
export const uploadNewProjectDocumentsURL = `${baseUrl}/uploadNewProjectDocuments`;
export const deleteNewProjectDocumentURL = `${baseUrl}/deleteNewProjectDocument`;
export const getProjectCandidateAdditionalDocumentByIdURL = `${baseUrl}/getProjectCandidateAdditionalDocumentById`;
export const uploadProjectCandidateAdditionalDocumentsURL = `${baseUrl}/uploadProjectCandidateAdditionalDocuments`;
export const deleteProjectCandidateAdditionalDocumentURL = `${baseUrl}/deleteProjectCandidateAdditionalDocument`;
export const removeProjectPictureURL = `${baseUrl}/removeProjectPicture`;
export const getCompanyProfilePdfURL = `${baseUrl}/getCompanyProfilePdf`;
export const getRequirementsDocument = `${baseUrl}/getRequirementsDocument`;
export const downloadPotentialCandidateDossiers = `${baseUrl}/downloadPotentialCandidateDossiers`;
export const deleteMultipleProjectCandidateDossier = `${baseUrl}/deleteMultipleProjectCandidateDossier`;
export const createProjectLinkURL = `${baseUrl}/addProjectLink`;
export const updateProjectLinkURL = `${baseUrl}/updateProjectLink`;
export const deleteProjectLinkURL = `${baseUrl}/removeProjectLink`;
// CandidateArchive
export const lienertBulkDeleteOldCandidatesURL = `${baseUrl}/lienertBulkDeleteOldCandidates`;
export const getCandidateByIdURL = `${baseUrl}/getCandidateById`;
export const updateCandidateSubSchemaURL = `${baseUrl}/updateCandidateSubSchema`;
export const getCandidateListBySearchTermURL = `${baseUrl}/getCandidateListBySearchTerm`;
export const getCandidateListByDetailSearchTermsURL = `${baseUrl}/getCandidateListByDetailSearchTerms`;
export const exportCandidateListByDetailSearchTermsURL = `${baseUrl}/getCandidateListByDetailSearchTermsForExport`;
export const updateCandidateURL = `${baseUrl}/updateCandidate`;
export const updateCandidateDocumentNameURL = `${baseUrl}/updateCandidateDocumentName`;
export const addCandidateSubSchemaEntryURL = `${baseUrl}/addCandidateSubSchemaEntry`;
export const removeCandidateSubSchemaEntryURL = `${baseUrl}/removeCandidateSubSchemaEntry`;
export const getProjectListByCandidateIdURL = `${baseUrl}/getProjectListByCandidateId`;
export const addPotentialCandidateURL = `${baseUrl}/addPotentialCandidate`;
export const updateCandidateDocumentTagURL = `${baseUrl}/updateCandidateDocumentTag`;
export const sendDataPrivacyMailURL = `${baseUrl}/sendDataPrivacyMail`;
export const getCandidateListByNameURL = `${baseUrl}/getCandidateListByName`;
export const createCandidateURL = `${baseUrl}/createCandidate`;
export const overwriteCandidateURL = `${baseUrl}/overwriteCandidate`;
export const mergeCandidateURL = `${baseUrl}/mergeCandidate`;
export const getCandidateChangeHistoryURL = `${baseUrl}/getCandidateChangeHistory`;
export const updateCandidatePictureURL = `${baseUrl}/updateCandidatePicture`;
export const updateIncomingCandidatePictureURL = `${baseUrl}/updateIncomingCandidatePicture`;
export const uploadCandidateDocumentURL = `${baseUrl}/uploadCandidateDocument`;
export const uploadIncomingCandidateDocumentURL = `${baseUrl}/uploadIncomingCandidateDocument`;
export const deleteIncomingCandidateURL = `${baseUrl}/deleteIncomingCandidate`;
export const getCandidatePdfDocumentURL = `${baseUrl}/getCandidatePdfDocument`;
export const getPSheetURL = `${baseUrl}/getPSheet`;
export const getISheetURL = `${baseUrl}/getISheet`;
export const getCandidateDossierURL = `${baseUrl}/getCandidateDossier`;
export const getShortListExcelURL = `${baseUrl}/getShortListExcel`;
export const getLatecomerListExcelURL = `${baseUrl}/getLatecomerListExcel`;
export const getLongListExcelURL = `${baseUrl}/getLongListExcel`;
export const deleteCandidateURL = `${baseUrl}/deleteCandidate`;
export const deleteCandidateDocumentsURL = `${baseUrl}/deleteCandidateDocuments`;
export const updateIncomingCandidateURL = `${baseUrl}/updateIncomingCandidate`;
export const getCandidateListByMatchingProfileURL = `${baseUrl}/getCandidateListByMatchingProfile`;
export const deleteCandidateDocumentURL = `${baseUrl}/deleteCandidateDocument`;
export const getCandidateListByMarkListIdURL = `${baseUrl}/getCandidateListByMarkListId`;
export const deleteCandidateImageURL = `${baseUrl}/deleteCandidateImage`;
export const getCandidateListByMatchingProjectIdURL = `${baseUrl}/getCandidateListByMatchingProjectId`;
export const addCandidateMarkListToProjectURL = `${baseUrl}/addCandidateMarkListToProject`;
export const getProjectCandidateDossierListURL = `${baseUrl}/getProjectCandidateDossierList`;
export const getProjectCandidateDossierListSenatorURL = `${baseUrl}/getProjectCandidateDossierListSenator`;
export const getProjectCandidateDossierURL = `${baseUrl}/getProjectCandidateDossierUrl`;
export const deleteProjectCandidateDossierURL = `${baseUrl}/deleteProjectCandidateDossier`;
export const setProjectCandidateDossierVisibleToCustomerURL = `${baseUrl}/setProjectCandidateDossierVisibleToCustomer`;
// general
export const getIncomingCandidateListURL = `${baseUrl}/getIncomingCandidateList`;
export const getIncomingCandidateByIdURL = `${baseUrl}/getIncomingCandidateById`;
export const updateIncomingCandidateDocumentNameURL = `${baseUrl}/updateIncomingCandidateDocumentName`;
export const deleteIncomingCandidateDocumentURL = `${baseUrl}/deleteIncomingCandidateDocument`;
export const getAllDetailSearchQueriesURL = `${baseUrl}/getAllDetailSearchQueries`;
export const loadDetailSearchURL = `${baseUrl}/loadDetailSearch`;
export const saveDetailSearchURL = `${baseUrl}/saveDetailSearch`;
export const deleteDetailSearchURL = `${baseUrl}/deleteDetailSearch`;
export const saveArchiveSearchResultToMarkListURL = `${baseUrl}/saveArchiveSearchResultToMarkList`;
export const getDuplicateCandidateListURL = `${baseUrl}/getDuplicateCandidateList`;

// serialMail & templates
export const getTemplateListURL = `${baseUrl}/getTemplateList`;
export const getSignatureListURL = `${baseUrl}/getSignatureList`;
export const getTemplatesURL = `${baseUrl}/getTemplates`;
export const getProjectNumbersByCandidateMarkListURL = `${baseUrl}/getProjectNumbersByCandidateMarkList`;
export const getTemplateByIdURL = `${baseUrl}/getTemplateById`;
export const getSignatureTemplateURL = `${baseUrl}/getSignatureTemplate`;
export const sendSerialMailURL = `${baseUrl}/sendSerialMail`;
export const sendTestMailURL = `${baseUrl}/sendTestMail`;
export const createTemplateURL = `${baseUrl}/createTemplate`;
export const updateTemplateURL = `${baseUrl}/updateTemplate`;
export const deleteTemplateURL = `${baseUrl}/deleteTemplate`;

// admin page
export const updateUserURL = `${baseUrl}/updateUser`;
export const getPastSearchesOfClientsURL = `${baseUrl}/getPastSearchesOfClients`;

// intranet
export const getFolderIntranetURL = `${baseUrl}/intranet/getFolder`;
export const createFolderIntranetURL = `${baseUrl}/intranet/createFolder`;
export const deleteFolderIntranetURL = `${baseUrl}/intranet/deleteFolder`;
export const renameFolderIntranetURL = `${baseUrl}/intranet/renameFolder`;
export const getFileIntranetURL = `${baseUrl}/intranet/getFile`;
export const deleteFileIntranetURL = `${baseUrl}/intranet/deleteFile`;
export const createFileIntranetURL = `${baseUrl}/intranet/createFile`;
export const createLinkIntranetURL = `${baseUrl}/intranet/createLink`;
export const updateLinkIntranetURL = `${baseUrl}/intranet/updateLink`;
export const deleteLinkIntranetURL = `${baseUrl}/intranet/deleteLink`;
export const createCompanyOffer = `${baseUrl}/createCompanyOffer`;
export const deleteCompanyOffer = `${baseUrl}/deleteCompanyOffer`;
export const getCompanyOfferList = `${baseUrl}/getCompanyOfferList`;
export const getAllOffersList = `${baseUrl}/getAllOffersList`;
export const updateCompanyOffer = `${baseUrl}/updateCompanyOffer`;

export const isDev = process.env.NODE_ENV === 'development';

export const stepstoneImageBaseURL = isDev ? '/img/stepstone' : 'https://app.senator-partners.de/img/stepstone';
export const stepstonePathToImagesURL = {
  personaltotal: '/personaltotal_gesamt',
  senator: '/senator_gesamt'
};
export const stepstoneImages = {
  personaltotal: [
    '/_Auge.jpg',
    '/Automobilhandel.jpg',
    '/Automotive_E-Mobilitaet.jpg',
    '/Bau_TGA_FM.jpg',
    '/Bewerbercoaching.jpg',
    '/Businesscoaching.jpg',
    '/Chemie_Pharma.jpg',
    '/Defence_Aerospace.jpg',
    '/Digitalisierung_4.0.jpg',
    '/_Digital_Online_Business.jpg',
    '/Eignungsdiagnostik.jpg',
    '/Energie_Versorgung.jpg',
    '/Engineering_TIC.jpg',
    '/Fashion_Media_Lifestyle.jpg',
    '/Forschung_Entwicklung.jpg',
    '/_Frau_Mappe.jpg',
    '/_Frau_Vordergrund.jpg',
    '/Getraenke.jpg',
    '/Giesserei_Aluminium.jpg',
    '/Handel_Dienstleistung.jpg',
    '/Holz-Moebelindustrie.jpg',
    '/Human_Resources.jpg',
    '/Industrieservice_Technische_Dienstleistungen.jpg',
    '/Internationaler_Vertrieb.jpg',
    '/IT_SAP.jpg',
    '/Konsum_und_Gebrauchsgueter.jpg',
    '/Logistik_Transport_Einkauf.jpg',
    '/_Mann_Fenster.jpg',
    '/_Mann_Handy.jpg',
    '/_Mann_Laptop.jpg',
    '/_Mann_Laptop_Koffer.jpg',
    '/_Mann_Tisch.jpg',
    '/Marketing_Vertrieb.jpg',
    '/Maschinenbau_2.jpg',
    '/Maschinenbau.jpg',
    '/Nahrungs-Genussmittel.jpg',
    '/Oeffentlicher_Dienst.jpg',
    '/Optoelektronik_Licht.jpg',
    '/Personal-und-Organisationsentwicklung.jpg',
    '/_Personen_Gespraech.jpg',
    '/_Personen_Rolltreppe3.jpg',
    '/_Personen_Tisch_oben.jpg',
    '/Projektmanagement.jpg',
    '/_Skyline_Haeuserfront_2.jpg',
    '/_Skyline_Haeuserfront.jpg',
    '/_Skyline.jpg',
    '/_Tablet.jpg',
    '/Verpackungstechnik.jpg',
    '/_Wachstum.jpg   '
  ],
  senator: [
    '/Active_Sourcing.jpg',
    '/Agribusiness.jpg',
    '/Anlagenbau.jpg',
    '/_Auge.jpg',
    '/Automatisierungstechnik.jpg',
    '/Automobilhandel.jpg',
    '/Automotive_E-Mobilitaet.jpg',
    '/Bau_TGA_FM.jpg',
    '/Change_Management.jpg',
    '/Compliance_Management.jpg',
    '/Consumer_Goods.jpg',
    '/Defence_Aerospace.jpg',
    '/Digitalisierung_4.0.jpg',
    '/_Digital_Online_Business.jpg',
    '/Digital_Online_Business.jpg',
    '/Einkauf_Beschaffung.jpg',
    '/Elektrotechnik.jpg',
    '/Entsorgung_und_Kreislaufwirtschaft.jpg',
    '/Erneuerbare_Energien_Energiewirtschaft.jpg',
    '/Finance_Accounting.jpg',
    '/Financial_Services.jpg',
    '/Forschung_Entwicklung.jpg',
    '/Foto_Imaging.jpg',
    '/_Frau_Bilder_Personal-und-Organisationsentwicklung.jpg',
    '/_Frau_Mappe.jpg',
    '/_Frau_Vordergrund.jpg',
    '/Fuehrung_durch_Wertschaetzung.jpg',
    '/Gesundheitswesen.jpg',
    '/Handel_Dienstleistung.jpg',
    '/HR_Prozessoptimierung.jpg',
    '/Human_Resources.jpg',
    '/Internationale_Rekrutierung.jpg',
    '/Internationaler_Vertrieb.jpg',
    '/IT_Telekommunikation.jpg',
    '/Kunststofftechnik.jpg',
    '/_Lebensmittelgeschaeft.jpg',
    '/Legal_Compliance.jpg',
    '/Logistik_Transport_Einkauf.jpg',
    '/_Mann_Fenster.jpg',
    '/_Mann_Gespraech_Bewerbercoaching.jpg',
    '/_Mann_Handy.jpg',
    '/_Mann_Laptop.jpg',
    '/_Mann_Laptop_Koffer.jpg',
    '/_Mann_Tisch.jpg',
    '/Marketing_Vertrieb.jpg',
    '/Maschinenbau.jpg',
    '/Medizintechnik.jpg',
    '/Oeffentlicher_Dienst.jpg',
    '/Optoelektronik_Licht.jpg',
    '/Outplacement_Newplacement.jpg',
    '/_Personen_Gespraech.jpg',
    '/_Personen_Tisch_oben.jpg',
    '/_Personen_Tisch_vorne.jpg',
    '/Pharma.jpg',
    '/Pre-Employment-Screening.jpg',
    '/Private_Equity.jpg',
    '/Qualitaetsmanagement.jpg',
    '/_Skyline_Haeuserfront_2.jpg',
    '/_Skyline_Haeuserfront.jpg',
    '/_Skyline.jpg',
    '/Sozialwesen.jpg',
    '/Stanz_Umformtechnik_und_Metallbearbeitung.jpg',
    '/Supply_Chain_Management.jpg',
    '/_Tablet.jpg',
    '/Unternehmensnachfolge.jpg',
    '/_Wachstum.jpg'
  ]
};
